import React from 'react'
import Typed from 'react-typed';
import logo from '../../asset/images/home.png'
import './intro.scss'
export default function Intro() {
  return <div className='intro'>
    <div className="intro-container">
      <div className='content'>
        <div>
          <h1>IngenioSoft</h1>
          <h3>
            <Typed
              strings={[
                'Diseño y construcción de aplicaciones web.',
                'Diseño y construcción de aplicaciones moviles.',
                'Analítica de datos.',
                'Inteligencia de negocios.'
              ]}
              typeSpeed={40}
              backSpeed={20}
              loop />
          </h3>
        </div>
      </div>
      <div className='images'>
        <div><img src={logo} alt="Logo" srcset="" /></div>
      </div>
    </div>
  </div>
}