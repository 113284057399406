import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import Navbar from './components/Navbar'
// import Footer from './components/Footer'
import Home from './pages/home/home.jsx';
export default function AppRouter(props) {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}