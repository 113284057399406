import React from 'react'
import Intro from '../../components/intro/intro'
import Navbar from '../../components/navbar/navbar'
import Contacts from '../../components/contacts/Contacts'
import Hero from '../../components/hero/Hero'
import Footer from '../../components/Footer'
import Proyects from '../../components/proyects/Proyects'
export default function Home(props) {
  return (<>
    <Navbar />
    <Intro />
    {/* <Hero />
    <Proyects />
    <Contacts />
    <Footer/> */}
  </>)
}