import React, { useState,useRef } from 'react'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import './register.scss'
export default function Register({ handleClose }) {
  const emailRef = useRef();
  const nameRef = useRef();
  const observationRef = useRef();
  return <>
    <Grid container className="register">
      <Grid item xs={12} sm={12}>
        <h3>Registrarse</h3>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          autoFocus
          id="name"
          label="Nombre Completo"
          type="text"
          fullWidth
          variant="outlined"
          ref={nameRef}
          autoComplete='off'
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          id="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          ref={emailRef} 
          autoComplete='off'
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          id="observation"
          label="Comentarios u observaciones"
          type="text"
          fullWidth
          variant="outlined"
          ref={observationRef} 
          autoComplete='off'
          multiline
        />
      </Grid>
      <Grid item xs={12} sm={12} className='btn'>
        <button>Registrar</button>
      </Grid>
    </Grid>
  </>
}