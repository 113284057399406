import React, { useState } from 'react'
import logoIngenoSoft from '../../asset/images/Ingeniosoft-textoblanco.png'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Register from '../register/register';

import './navbar.scss'
export default function Navbar(props) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false)

  const handleClose = () => setShowRegisterModal(false)

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  }

  return <nav className={isScrolled ? "home scrolled" : 'home'}>
    <Dialog
      open={showRegisterModal}
      onClose={handleClose}
      aria-describedby="modal-publication"
      fullWidth
      maxWidth={'xs'}
    >
      <Register
        handleClose={handleClose}
      />
    </Dialog>
    <div>
      <img src={logoIngenoSoft} alt="logo ingeniosoft" className={isScrolled ? "scrolled" : ''} />
      {/* <h1 className={isScrolled ? "scrolled" : ''}>IngenioSoft</h1> */}
    </div>
    <div><input type='text' placeholder='Buscar' /></div>
    <div className='btn'>
      <button>Iniciar Sesión</button>
      <button
        onClick={() => setShowRegisterModal(true)}
      >Registrarse</button>
    </div>
  </nav>
}